import { Injectable, signal } from '@angular/core';
import { PageModel } from 'src/app/core/models/book.model';
import { DynamicComponent } from 'src/app/core/services/dynamic-component.service';
import { WindowResizeService } from 'src/app/core/services/window-resize.service';
import { EPages } from 'src/app/shared/enums/pages.enum';

type PageFieldType = 'lessonId' | 'spaceId' | 'categoryId';

type PageValidationParams = {
  currentPageId: number;
  pageType: EPages;
  field: PageFieldType;
};

type NewPageParams = {
  page: DynamicComponent;
  pageType: EPages;
  position?: number;
};

type PageComponent = DynamicComponent & { pageType: EPages };

@Injectable({
  providedIn: 'root'
})
export class TeacherPageControllerService {
  public isDesktop = !this.windowResizeService.isMobileWidthSize;
  public currentPage = signal<number>(0);
  public selectedSummaryPage = signal<number>(0);
  public finalPages: PageComponent[] = [];
  public initialPages: PageComponent[] = [
    {
      name: 'Introdução',
      render: 'IntroComponent',
      pageType: EPages.intro
    },
    {
      name: 'Categorias',
      render: 'CategoriesComponent',
      pageType: EPages.categories
    }
  ];
  public pages: PageComponent[] = [...this.initialPages];

  constructor(private windowResizeService: WindowResizeService) {}

  handleNextPage(coverFront?: HTMLElement) {
    if (this.currentPage() === this.pages.length - 1) {
      return;
    }

    if (!this.isDesktop) {
      if (
        this.currentPage() === 0 &&
        !coverFront?.classList.contains('flipped')
      ) {
        coverFront?.classList.add('flipped');
        return;
      } else {
        this.currentPage.set(this.currentPage() + 1);
        return;
      }
    }

    if (this.currentPage() === 0 && this.isDesktop) {
      this.currentPage.set(this.currentPage() + 1);
      return;
    }
    this.currentPage.set(this.currentPage() + 2);
  }

  handlePreviousPage() {
    if (this.currentPage() === 0) {
      return;
    }

    if (this.currentPage() === 1 || !this.isDesktop) {
      this.currentPage.set(this.currentPage() - 1);
      return;
    }
    this.currentPage.set(this.currentPage() - 2);
  }

  handleSummaryNavigation(selectedPageIndex: number) {
    let navigationIndex = selectedPageIndex;

    if (selectedPageIndex === this.currentPage() + 1) {
      if (this.isDesktop && this.currentPage() !== 0) {
        return;
      } else if (!this.isDesktop) {
        return;
      }
    }

    if (!this.isDesktop && selectedPageIndex === this.currentPage()) {
      navigationIndex = selectedPageIndex - 1;
    }

    if (!this.isDesktop || selectedPageIndex % 2 === 0) {
      navigationIndex = selectedPageIndex - 1;
    }

    this.currentPage.set(navigationIndex);
  }

  handleCloseBook() {
    if (this.currentPage() === 0) {
      return;
    }

    this.currentPage.set(0);
  }

  setZIndex(pages: PageComponent[]): PageComponent[] {
    const totalPages = pages.length;

    return pages.map((component, zIndex) => ({
      ...component,
      wrapperProps: {
        zIndex: totalPages - zIndex
      }
    }));
  }

  buildFinalPages(pages: PageModel, isConectaBook: boolean) {
    const everyClass: PageComponent = {
      name: '34 aulas',
      render: 'EveryClassComponent',
      pageType: EPages.all_lessons
    };
    const resourceMaterial: PageComponent = {
      name: 'Recursos',
      render: 'ResoucesMaterialsComponent',
      pageType: EPages.resources_materials
    };

    const likeNextYear: PageComponent = {
      name: 'Gostinho do ano que vem',
      render: 'LikeNextYearComponent',
      pageType: EPages.like_next_year
    };

    if (isConectaBook) {
      this.finalPages?.push(likeNextYear);
      return;
    }

    if (pages.creativeSpaces.length > 0) {
      const introCreativeSpace: PageComponent = {
        name: 'Introdução do espaço criativo',
        render: 'IntroCreativeSpaceComponent',
        pageType: EPages.intro_creative_space
      };
      this.finalPages?.push(introCreativeSpace);

      pages.creativeSpaces.forEach((creativeSpaceData) => {
        const creativeSpace: PageComponent = {
          name: 'Espaço criativo',
          render: 'CreativeSpaceComponent',
          pageType: EPages.creative_space,
          props: { spaceId: `${creativeSpaceData.id}` }
        };
        this.finalPages?.push(creativeSpace);
      });
    }
    this.finalPages = [
      ...this.finalPages,
      everyClass,
      resourceMaterial,
      likeNextYear
    ];
  }

  buildPagesArray(
    pages: PageModel,
    isConectaBook: boolean,
    shouldRenderAuthorsPage: boolean
  ) {
    if (shouldRenderAuthorsPage) {
      this.addNewDynamicPage({
        page: {
          name: 'Autores',
          render: 'AuthorsPageComponent'
        },
        pageType: EPages.authors,
        position: 0
      });
    }

    pages.categories.forEach((categoryData) => {
      const categoryPage: PageComponent = {
        name: 'Categoria de aulas',
        render: 'ClassCategoryComponent',
        pageType: EPages.category_lesson,
        props: { categoryId: `${categoryData.category.id}` }
      };

      this.pages.push(categoryPage);

      categoryData.lessons.forEach((lesson) => {
        const lessonPage: PageComponent = {
          name: 'Aula',
          render: 'LessonComponent',
          pageType: EPages.lesson,
          props: { lessonId: `${lesson.id}` }
        };

        this.pages.push(lessonPage);
      });
    });

    this.buildFinalPages(pages, isConectaBook);
    this.pages.push(...this.finalPages);
    this.pages = this.setZIndex(this.pages);
  }

  addNewDynamicPage({ page, pageType, position }: NewPageParams) {
    const newPage = { ...page, pageType };
    if (
      position !== undefined &&
      position >= 0 &&
      position <= this.pages.length
    ) {
      this.pages.splice(position, 0, newPage);
    } else {
      this.pages.push(newPage);
    }
  }

  validatePreviousPageContentId(contentId: number): boolean {
    const previousPage = this.pages[this.currentPage() - 1];

    if (previousPage.props?.['lessonId']) {
      return previousPage.props?.['lessonId'] === contentId;
    }
    if (previousPage.props?.['categoryId']) {
      return previousPage.props?.['categoryId'] === contentId;
    } else {
      return previousPage.props?.['spaceId'] === contentId;
    }
  }

  validateCurrentPageContentId(
    contentId: number,
    field: PageFieldType
  ): boolean {
    const currentPageProps = this.pages[this.currentPage()].props;

    return this.isDesktop
      ? currentPageProps?.[field] === contentId ||
          this.validatePreviousPageContentId(contentId)
      : currentPageProps?.[field] === contentId;
  }

  validateCurrentPage(page: EPages) {
    if (this.isDesktop && this.currentPage() > 2) {
      return (
        this.pages[this.currentPage()].pageType === page ||
        this.pages[this.currentPage() - 1].pageType === page
      );
    }

    return this.pages[this.currentPage()].pageType === page;
  }

  validateCurrentPageAndContentId({
    pageType,
    currentPageId,
    field
  }: PageValidationParams) {
    return (
      this.validateCurrentPage(pageType) &&
      this.validateCurrentPageContentId(currentPageId, field)
    );
  }

  get lessonRegistersPages(): PageComponent[] {
    return this.pages.filter((page) => page.pageType === EPages.lesson);
  }
}
